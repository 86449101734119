<template>
	<v-row>
		<v-col cols="12">
			<v-card>
				<v-row no-gutters>
					<v-col>
						<v-card-text class="pr-0">
							<v-select
								:items="filiaisFiltro"
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								:disabled="carregando"
								v-model="busca.idempresa"
								hide-details
							></v-select>
						</v-card-text>
					</v-col>
					<v-col>
						<v-card-title>
							<v-btn :loading="carregando" :disabled="busca.idempresa.length <= 0 || carregando" width="100%" height="40px" @click="exportar()" class="primary"><v-icon size="25" class="mr-1">mdi-download</v-icon>Baixar arquivo</v-btn>
						</v-card-title>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<v-col cols="12"> <!-- v-if="dados.length <= 0" -->
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text class="text-center pt-8">
					<strong class="text-h6" >Selecione a filial desejada e depois clique em "BAIXAR ARQUIVO" para efetuar a exportação e iniciar balanço.</strong>
					<br />
					<br />
					<v-icon
						color="primary lighten-5"
						class="my-2"
						size="100"
					>mdi-file-download</v-icon>
				</v-card-text>
			</v-card>
		</v-col>
<!--		<v-col v-else>-->
<!--			<v-card>-->
<!--				<v-data-table-->
<!--					:headers="[-->
<!--								{text: 'CÓDIGO DE BARRAS', value: 'codbar', class:'text-truncate', sortable: false},-->
<!--								{text: 'CÓDIGO PRODUTO', value: 'idsubproduto', class:'text-truncate', sortable: false},-->
<!--								{text: 'PRODUTO', value: 'produto', class:'text-truncate', sortable: false},-->
<!--								{text: 'UNIDADE', value: 'unidade', class:'text-truncate', sortable: false},-->
<!--								{text: 'PREÇO', value: 'preco', class:'text-truncate', sortable: false},-->
<!--								{text: 'QUANTIDADE ESTOQUE', value: 'qtdatualestoque', class:'text-truncate', sortable: false},-->
<!--								{text: 'CATEGORIA', value: 'categoria', class:'text-truncate', sortable: false},-->
<!--					]"-->
<!--					:items="dados"-->
<!--					hide-default-footer-->
<!--					:page.sync="page"-->
<!--					:items-per-page="100"-->
<!--					@page-count="pageCount = $event"-->
<!--				></v-data-table>-->
<!--				<v-divider />-->
<!--				<div class="text-center py-2" v-if="pageCount>0">-->
<!--					<v-pagination-->
<!--						:disabled="carregando"-->
<!--						:total-visible="6"-->
<!--						v-model="page"-->
<!--						:length="pageCount"-->
<!--					></v-pagination>-->
<!--				</div>-->
<!--				<v-progress-linear indeterminate absolute :active="carregando" />-->
<!--			</v-card>-->
<!--		</v-col>-->
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import axios from "axios";

export default {
	mixins: [mixinFilial],
	name: "BalancoExportador",
	data: () => ({
		carregando: false,
		carregandoSkeleton: false,
		busca: {
			idempresa: '',
		},
		dados: [],
		page: 1,
		pageCount: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 33
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listar() {
			this.carregando = true;
			this.carregandoSkeleton = true;
			return axios
				.post(`${this.backendUrl}balanco/arquivo/listar`, {
					idempresa: this.busca.idempresa || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		exportar() {
			this.carregando = true;
			this.carregandoSkeleton = true;
			return axios
				.post(`${this.backendUrl}balanco/exportar`, {
					idempresa: this.busca.idempresa || null,
				})
				.then((res) => {
					const url = window.URL.createObjectURL(
						this.base64ToBlob(res.data.arquivo)
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "balanco_filial_" + this.busca.idempresa + ".txt");
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		base64ToBlob(base64) {
			const decodedData = window.atob(base64);
			const uInt8Array = new Uint8Array(decodedData.length);
			for (let i = 0; i < decodedData.length; ++i) {
				uInt8Array[i] = decodedData.charCodeAt(i);
			}
			return new Blob([uInt8Array], { type: "text/plain" });
		},
	},
};
</script>

<style>

</style>